import React, { useState, useEffect } from "react";
import "./header.css";

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [previousScrollPos, setPreviousScrollPos] = useState(0);
  const [activeLink, setActiveLink] = useState("#");
  const [scrollY, setScrollY] = useState(0);
  const scrollThreshold = 500; 

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const currentScrollPos = window.pageYOffset;
      setScrollY(currentScrollPos);
      if (previousScrollPos > currentScrollPos && currentScrollPos < scrollY - scrollThreshold) {
        setShowHeader(true);
      } else if (previousScrollPos < currentScrollPos) {
        setShowHeader(false);
      }
      setPreviousScrollPos(currentScrollPos);
    });
  }, [previousScrollPos, scrollY]);

  return (
    <section id="header">
      <div className={`header-container2 ${!showHeader ? "hide" : ""}`}>
        <nav className="header-navbar__container">
          <h2 className="header__brand-name">
            {" "}
            <a href="https://ciberkids.es">Ciberkids</a>
          </h2>
          <ul className="header__navbar-menu">
            <li className="header__li">
              <a
                href={window.location.href.includes("join") ? "https://ciberkids.es/#about-us" : "#about-us"}
                onClick={() => setActiveLink("#about")}
                className={activeLink === "#about-us" ? "active" : ""}
              >
                Nuestra visión
              </a>
            </li>
            <li className="header__li">
              <a
                href={window.location.href.includes("join") ? "https://ciberkids.es/#methodology" : "#methodology"}
                onClick={() => setActiveLink("#methodology")}
                className={activeLink === "#methodology" ? "active" : ""}
              >
                Metodología
              </a>
            </li>
            <li className="header__li">
              <a
                href={window.location.href.includes("join") ? "https://ciberkids.es/#dates" : "#dates"}
                onClick={() => setActiveLink("#dates")}
                className={activeLink === "#dates" ? "active" : ""}
              >
                Fechas
              </a>
            </li>
          </ul>
          <button
            className="join-btn header__join-btn"
            onClick={() => window.location.assign("https://ciberkids.es/join")}
          >
            ÚNETE
          </button>
        </nav>
      </div>
    </section>
  );
};

export default Header;

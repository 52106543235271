import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <section id='footer'>
      <div className='footer__space'></div>
      <div className='footer__container'>
        <div>
          <h2 className='footer__title'>POLÍTICAS</h2>
          <ul>
            <li className='footer__list'>Términos y condiciones</li>
            <li className='footer__list'>Política de privacidad</li>
            <li className='footer__list'>Política de Cookies</li>
            <li className='footer__list'>Configuración de cookies</li>
          </ul>
        </div>
        <div>
          <h1 className='footer__brand'>CiberKids</h1>
        </div>
        <div>
        <h2 className='footer__title'>CONTACTO</h2>
          <ul className='footer__ul'>
            <li className='footer__list'>correo@gmail.com</li>
            <li className='footer__list'>(+34)66412332</li>
            <li className='footer__list'>Valencia, España</li>
          </ul>
        <div className='footer__space'></div>
        </div>
      </div>
      <div className="footer__copyright"></div>
          <p className='footer__copyright-text'>©2023 - CiberKids  |   Todos los derechos reservados</p>
    </section>
  )
}

export default Footer
import React from 'react'
import { MobileOutlined, MailOutlined } from '@ant-design/icons';
import "./contact.css"

const Contact = () => {
  return (
    <section id='contact'>
      <br />
      <br />
      <div className='contact__container'>
        <h1 className='contact__title'>Contacto</h1>
        <ul className='contact__info'>
          <li><MobileOutlined /> | +34 632132343</li>
          <br />
          <li><MailOutlined /> | ciberkids.informacion@gmail.com</li>
        </ul>
      </div>
    </section>
  )
}

export default Contact

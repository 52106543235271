import React from 'react'
import Dates from '../Dates/Dates'
import Home from '../Home/Home'
import Methodology from '../Methodology/Methodology'
import Wellcome from '../Wellcome/Wellcome'
import AboutUs from '../AboutUs/AboutUs'




const HomePage = () => {
  return (
    <>
      <Home />
      <AboutUs />
      <Methodology />
      <Dates />
      <Wellcome />
    </>
  )
}

export default HomePage
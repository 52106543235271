import React from "react";
import "./aboutUs.css";
import ninoPc from "../assets/nino-en-pc-ilustracion.png";
import cohete from "../assets/cohete-ilustracion.png";

const AboutUs = () => {
  return (
    <section id="about-us">
      <div className="about-us__container">
        <img
          className="about-us__kid-illustration"
          src={ninoPc}
          alt=" ilustración de un niño programando en un pc "
        />
        <div>
          <h1 className="about-us__title">NUESTRA VISIÓN</h1>
          <p className="about-us__text">
            Creemos firmemente en el poder de la tecnología para transformar
            vidas y mejorar el mundo, y queremos ayudar a las siguientes
            generaciones a aprovechar al máximo su potencial.
            <br /><br />
            Nuestro objetivo
            es ofrecer una experiencia de aprendizaje lúdica y accesible para
            todos, sin importar su habilidad técnica previa. Queremos enseñar a
            los niños a pensar críticamente y a resolver problemas de manera
            creativa a través del aprendizaje de la tecnología.
          </p>
        </div>
        <img
          className="about-us__rocket-illustration"
          src={cohete}
          alt="ilustración de un cohete volando"
        />
      </div>
    </section>
  );
};

export default AboutUs;

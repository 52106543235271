import React from "react";
import "./dates.css";

const Dates = () => {
  return (
    <section id="dates">
      <div>
        <h1 className="dates__title">FECHAS</h1>
        <div className="dates-container">
          {/* priemra caja */}
          <div className="dates__card-1">
            {/* texto fech */}
            <div>
              <h1 className="dates__card-title">
                Taller <br />
                Programación <br />
                Web
              </h1>
              <h4 className="dates__location">Calle de buenos Aires,23</h4>

               <div>
            <button className="join-btn dates__btn" onClick={() => window.location.href='https://ciberkids.es/join'}>ÚNETE</button>
            </div>
            </div>
            {/* FEcha y años */}
            <div>
              <div className="date-container dates__day-month">
                <div className="date-number">19</div>
                <div className="date-month">FEB</div>
              </div>

              <div className="age-container-2">
                <h3 className="dates__age-container-2-text">DE 8 A 11 AÑOS</h3>
              </div>
            </div>
          </div>
          {/* separacion */}
          {/* segunda caja */}
          <div className="dates__card-2">
            <div>
              <h1 className="dates__card-title">
                Taller <br />
                Programación <br />
                Web
              </h1>
              <h2 className="dates__next-season">PRÓXIMAMENTE!</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dates;

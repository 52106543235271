import React from "react";
import "./methodology.css";
import adventureImage from "../assets/hombre-de-aventura.png";
import manPc from "../assets/hombre-sentado-portatil.png"
const Methodology = () => {
  return (
    <section id="methodology">
      <br />
      <h1 className="methodology__section-title">METODOLOGÍA</h1>

      <div className="methodology__container-1">
    <img
      className="methodology__image-container-1"
      src={adventureImage}
      alt="imagen de niños programando"
    />
    <div className="methodology__container-text">
      <div className="methodology__age-limit">
        <div className="age-container">
          <h3>DE 8 A 11 AÑOS</h3>
        </div>

        <div className="methodology__title-1">
          <h1 className="methodology__title-1-color">APRENDIZAJE</h1>
          <h2>EN NIVELES</h2>
        </div>
        <p className="methodology__text-1">
          El aprendizaje se ha dividido en niveles para que los niños
          puedan sentir una sensación de logro y progreso. Cada nivel
          es una misión con desafíos específicos y recompensas para
          motivar a los niños a seguir aprendiendo.
        </p>
        <button className="join-btn methodology-btn" onClick={() => window.location.href='https://ciberkids.es/join'}>ÚNETE</button>
      </div>
    </div>
  </div>

  <div className="methodology-inverted__container">
    <div className="methodology-inverted__text-container">
      <div className="methodology-inverted__age-limit">
        <div className="age-container">
          <h3>DE 8 A 11 AÑOS</h3>
        </div>

        <div className="methodology-inverted__title">
          <h2>LEARN</h2>
          <h1 className="methodology-inverted__title-color">BY DOING</h1>
        </div>
        <p className="methodology-inverted__text">
          La metodología "learn by doing" se centra en el aprendizaje
          práctico y la solución de problemas. Los estudiantes no solo
          escuchan o leen sobre un tema, sino que también tienen la
          oportunidad de poner en práctica lo que han aprendido en la vida
          real.
        </p>
        <button className="join-btn methodology-inverted-btn" onClick={() => window.location.href='https://ciberkids.es/join'}>ÚNETE</button>
      </div>
    </div>
    <img
      className="methodology-inverted__image-container"
      src={manPc}
      alt="ilustracion 3d hombre sentado con un pc portatil"
    />
  </div>
      <br />
      <br />
      <br />
    </section>
  );
};

export default Methodology;

/* <div className="age-container">
  <h3>DE 8 A 11 AÑOS</h3>
</div>
<div className="join-btn-container">
  <h2 className="join-btn-text">ÚNETE</h2>
</div>

<div className="date-container">
  <div className="date-number">19</div>
  <div className="date-month">FEB</div>
</div> */

import React from "react";
import "./home.css";
import imagenN from "../assets/Home-niños-vector.png";

const Home = () => {
  return (
    <section id="home">
      <div className="home-container">
        <div className="home-text__container">
        <h1 className="home__title">HACEMOS LA PROGRAMACIÓN DIVERTIDA.</h1>
        <button className="join-btn home__btn" onClick={() => window.location.href='https://ciberkids.es/join'}>ÚNETE</button>
        </div>
        <img className="home__image" src={imagenN} alt="imagen de niños programando" />
      </div>
    </section>
  );
};

export default Home;

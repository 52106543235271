import React from 'react'
import Contact from '../Subscribe/Contact/Contact'
import Subscribe from '../Subscribe/Subscribe'

const JoinPage = () => {
  return (
    <>
    <Contact/>
    <Subscribe/>
    </>
  )
}

export default JoinPage
import React, { useState, useRef } from "react";
import "./subscribeForm.css";
import iconForm from "../../assets/icon-form.png";
import emailjs from "@emailjs/browser";
import { Alert } from "antd";

const SubscribeForm = () => {
  const [formOption, setFormOption] = useState(null);
  const [formData, setFormData] = useState({});
  const [formSent, setFormSent] = useState(false); // Estado que indica si el formulario se ha enviado con éxito

  //MOSTRAR DIFERENTES OPCIONES DEL RADIO
  const handleFormOption = (e) => {
    setFormOption(e.target.value);
  };

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    console.log(formData);
  };

  //EMAILJS
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dosu5kp",
        "template_o0wao37",
        form.current,
        "T-ju6SgAPyDf-7Gw6"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Mensaje enviado correctamente");

          setFormSent(true);
          setTimeout(() => {
            window.location.href = "https://ciberkids.es";
          }, 3000);
        },
        (error) => {
          console.log(error.text);
          console.log("El mensaje no se envió");
        }
      );
  };

  //VALIDACIONES EN EL FORMULARIO

  return (
    <>
      <div className="contact-form__title">
        <img
          src={iconForm}
          className="form-icon"
          alt="ilustración de un formulario"
          style={{ width: "100px", height: "100px" }}
        />
        <h1>Formulario</h1>
      </div>

      <form className="subscribe-form" ref={form} onSubmit={sendEmail}>
        <div className="contact-form__container">
          <div>
            <label className="contact-form__description" htmlFor="client_name">
              Nombre:
            </label>
            <input
              placeholder="Escribe tu nombre"
              className="contact-form__input"
              type="text"
              id="client_name"
              name="client_name"
              onChange={handleFormData}
              required
              maxLength={30}
              minLength={3}
              pattern="[A-Za-záéíóúñÁÉÍÓÚÑ ]{3,30}"
              title="Por favor, escribe solo letras en este campo"
            />
          </div>
          <div>
            <label className="contact-form__description" htmlFor="client_email">
              E-Mail:
            </label>
            <input
              placeholder="Escribe tu email"
              className="contact-form__input"
              type="email"
              id="client_email"
              name="client_email"
              onChange={handleFormData}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
          </div>
          <div>
            <label className="contact-form__description" htmlFor="client_phone">
              Teléfono (+34):
            </label>
            <input
              placeholder="Escribe tu número de teléfono"
              className="contact-form__input"
              type="tel"
              id="client_phone"
              name="client_phone"
              onChange={handleFormData}
              required
              pattern="[0-9]{9}"
              maxLength={9}
              title="Por favor, escribe solo números en este campo y un máximo de 9 dígitos"
            />
          </div>
        </div>

        {/* //RADIO */}
        <div className="contact-form-radio">
          <p className="contact-form__description">¿Qué quieres hacer?</p>

          <div className="contact-form-radio-input-label">
            <input
              className="contact-form-radio__input"
              type="radio"
              id="requestInfo"
              name="formOption"
              value="requestInfo"
              onChange={handleFormOption}
            />
            <label className="contact-form-radio__text" htmlFor="requestInfo">
              Pedir información
            </label>
          </div>

          <div>
            <input
              className="contact-form-radio__input"
              type="radio"
              id="reserveSpot"
              name="formOption"
              value="reserveSpot"
              onChange={handleFormOption}
            />
            <label className="contact-form-radio__text" htmlFor="reserveSpot">
              Reservar plaza
            </label>
          </div>
        </div>

        {formOption === "reserveSpot" && (
          <div className="contact-form__container">
            <div>
              <label className="contact-form__description" htmlFor="date">
                Fecha:
              </label>
              <select
                className="contact-form__input"
                name="date"
                id="date"
                required>
                <option value="" disabled>
                  Selecciona una fecha
                </option>
                <option value="19 de Febrero 2023">19 de Febrero 2023</option>
                <option value="25 de Febrero 2023">25 de Febrero 2023</option>
              </select>
            </div>
            <div>
              <label
                className="contact-form__description"
                htmlFor="student_name">
                Nombre y Apellidos del Alumno:
              </label>
              <input
                placeholder="Escribe el nombre y apellidos del alumno"
                className="contact-form__input"
                type="text"
                id="student_name"
                name="student_name"
                onChange={handleFormData}
                maxLength={30}
                minLength={3}
                pattern="[A-Za-záéíóúñÁÉÍÓÚÑ ]{3,30}"
                title="Por favor, escribe solo letras en este campo"
                required
              />
            </div>
            <div>
              <label
                className="contact-form__description"
                htmlFor="student_age">
                Edad:
              </label>
              <input
                placeholder="Escribe la edad del alumno"
                className="contact-form__input"
                type="number"
                id="student_age"
                name="student_age"
                onChange={handleFormData}
                min={"8"}
                max={"11"}
                defaultValue="8"
                title="Por favor, escribe una edad de 8 a 11 años"
                required
              />
            </div>
            <div>
              <label
                className="contact-form__description"
                htmlFor="paymentMethod">
                Método de Pago:
              </label>
              <select
                className="contact-form__input"
                id="paymentMethod"
                name="paymentMethod"
                onChange={handleFormData}
                required>
                <option value="" disabled>
                  Selecciona un método de pago
                </option>
                <option value="transfer">Transferencia</option>
                <option value="cash">En Efectivo</option>
              </select>
            </div>
          </div>
        )}
        {formOption === "requestInfo" && (
          <div className="contact-form__container">
            <div>
              <br />
              <label
                className="contact-form__description"
                htmlFor="additionalInfo">
                Información Adicional:
              </label>
              <textarea
                className="contact-form__input contact-form__input-textarea"
                id="message"
                name="message"
                onChange={handleFormData}
                placeholder="Escribe aquí tu consulta"
              />
            </div>
          </div>
        )}
        {formSent && (
          <Alert
            message="Se ha enviado el formulario, muchas gracias!"
            type="success"
            showIcon
            closable
            onClose={() => {
              setFormSent(false);
              window.location.href = "https://ciberkids.es";
            }}
          />
        )}
        <br />
        <button
          className="join-btn"
          type="submit"
          value="Send"
          onClick={handleSubmit}>
          Enviar
        </button>
        <br />
      </form>
    </>
  );
};

export default SubscribeForm;

import React from 'react'
import ContactUs from './ContactUs/ContactUs'
import "./subscribe.css"
import SubscribeForm from './SubscribeForm/SubscribeForm'

const Subscribe = () => {
  return (
    <section id='subscribe'>
      <SubscribeForm/>
    {/* <ContactUs/> */}
    </section>
  )
}

export default Subscribe
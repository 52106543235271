import React from 'react'
import "./wellcome.css"

const Wellcome = () => {
  return (
    <section id='wellcome'>
      <br />
      <div className='wellcome__container'>
          <h1 className='wellcome__title'>Bienvenido a CiberKids</h1>
          <p className='wellcome__p'>Invierte en el futuro de tu hijo, introduciendo a la era digital!</p>
          <button className='wellcome__join-btn wellcome__btn' onClick={() => window.location.href='https://ciberkids.es/join'}>ÚNETE</button>
      </div>
    </section>
  )
}

export default Wellcome